import { CardEventModel, Competitor, Country, Game, GameCompetitor } from '@arland-bmnext/api-data'

export const getGameCompetitors = (game: Game | CardEventModel, competitors?: GameCompetitor[]): string[] => {
  if (competitors?.length > 0) {
    const res = competitors.reduce<string[]>((accumulator: string[], cur: GameCompetitor) => {
      if (cur.name != null && cur.name != '') accumulator.push(cur.name)
      return accumulator
    }, [])
    if (competitors.length > 0) return res
  }
  return game?.name?.split(new RegExp(/\s(?:\-|\@)\s/))
}

export const getGameCompetitorCountryCode = (countries: Country[], competitor: Competitor) => {
  return (
    countries.find((c) => c.id === competitor?.countryId || c['isoAlpha3'] === competitor?.countryCode)?.shortSign ??
    competitor?.countryCode
  )
}

export const getCountryCodeByGameCategory = (
  countries: Country[],
  gameCategoryShortSign: string,
  gameCategoryCountryId?: number
) => {
  const countryCode =
    countries?.find((country) =>
      gameCategoryCountryId
        ? country.id === gameCategoryCountryId
        : country['isoAlpha3']?.toLowerCase() === gameCategoryShortSign?.toLowerCase()
    )?.shortSign ?? gameCategoryShortSign
  return countryCode
}
