import {
  BetSlipItemType,
  BonusType,
  CheckBetSlipItemStatus,
  Game,
  GameMode,
  MarketOptions
} from '@arland-bmnext/api-data'
import {
  faCaretDown,
  faCaretUp,
  faExclamationCircle,
  faRectangleList,
  faRocket,
  faTimes
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useEffect, useRef } from 'react'
import { useBetslipContext } from '../../../context/betslip.context'
import { useCommonContext } from '../../../context/common.context'
import { useSportsBettingSettings } from '../../../lib/content'
import { BetServiceChangedOdd, BetSlipServiceBetSlipItem, BetTypes } from '../../../services/bet/bet.models'
import { formatDateWithTime } from '../../../util/date'
import { EnumTranslator } from '../../../util/enum-translator'
import { getFormattedOddValue } from '../../../util/odds'
import { buildEventUrl } from '../../../util/url'
import { BetBuilderTag } from '../../core/Tag'
import Switch from '../../forms/elements/Switch'
import BetBuilderOdd from '../BetBuilderOdd'
import { faFireFlameCurved } from '@fortawesome/pro-solid-svg-icons'

const ChangedOdd = ({ newOddValue, oddsChange }) => {
  const { oddsFormat } = useCommonContext()

  return (
    <div
      className={`odds-change flex px-1 h-[20px] space-x-1 items-center rounded-sm ${
        oddsChange > 0 ? 'odds-change-up bg-green-500' : 'odds-change-down bg-red-500'
      } text-white`}
    >
      <FontAwesomeIcon icon={oddsChange > 0 ? faCaretUp : faCaretDown} />
      <span className="odds-change-value text-xs">{getFormattedOddValue(oddsFormat, newOddValue)}</span>
    </div>
  )
}

const BoostedOdd = ({ oddValue, boostedOddValue, boosterPercentage, bonusType }) => {
  const { oddsFormat } = useCommonContext()

  const getIcon = () => {
    switch (bonusType) {
      case BonusType.SuperBoostToken:
        return faFireFlameCurved
      case BonusType.BetBoostToken:
        return faRocket
      default:
        return
    }
  }

  const getBoostedOddValue = () => {
    switch (bonusType) {
      case BonusType.SuperBoostToken:
        return getFormattedOddValue(oddsFormat, boostedOddValue)
      case BonusType.BetBoostToken:
        return getFormattedOddValue(oddsFormat, oddValue + oddValue * (boosterPercentage / 100))
      default:
        return
    }
  }
  return (
    <div
      className={`odds-change flex px-1 h-[20px] space-x-1 items-center rounded-sm odds-change-up bg-orange-500 text-white`}
    >
      <FontAwesomeIcon icon={getIcon()} />
      <span className="odds-change-value text-xs">{getBoostedOddValue()}</span>
    </div>
  )
}

type BetSlipItemProps = {
  item: BetSlipServiceBetSlipItem
  removeOdd: (gameId: number) => Promise<void>
}

const BetslipItem = ({ item, removeOdd }: BetSlipItemProps) => {
  const { t } = useTranslation('bets')
  const { oddsFormat } = useCommonContext()
  const betslipContext = useBetslipContext()
  const sportsBettingSettings = useSportsBettingSettings()
  const itemErrors = betslipContext.checkForItemErrors(item.odd.id)
  const isBetBuilderMarket = (item?.market?.options & MarketOptions.BetBuilderMarket) === MarketOptions.BetBuilderMarket

  let oddsChange = 0
  let changedOdd = betslipContext.changedLiveOdds?.find((odd) => odd.oddId === item.oddId)
  if (changedOdd) {
    oddsChange = changedOdd.newValue - changedOdd.oldValue
  } else {
    const oddsChangeItemError = itemErrors?.find(
      (itemError) =>
        itemError.status === CheckBetSlipItemStatus.OddLowered || itemError.status === CheckBetSlipItemStatus.OddRaised
    )
    if (oddsChangeItemError) {
      changedOdd = new BetServiceChangedOdd(item.oddId, item.oddValue, oddsChangeItemError.oddValue)
      oddsChange = oddsChangeItemError.oddValue - item.oddValue
    }
  }

  const isBoostedOdd = betslipContext.selectedTokenBonus?.boosterOddId === item.oddId
  const boostedOddValue = isBoostedOdd
    ? betslipContext.selectedTokenBonus.bonusType === BonusType.SuperBoostToken
      ? betslipContext.selectedTokenBonus.boosterOddValue
      : betslipContext.selectedTokenBonus.bonusType === BonusType.BetBoostToken
        ? betslipContext.selectedTokenBonus.boosterPercentage
        : undefined
    : undefined

  return (
    <div
      className={`betslip-item flex flex-col bg-betslipItem text-betslipItemContrast border-y-[1px] border-neutral last:mb-0 w-full justify-between`}
    >
      <div className="betslip-item-inner flex w-full">
        <div
          className="betslip-item-remove flex items-center px-4 bg-gray-500 bg-opacity-5 transition cursor-pointer hover:bg-opacity-20"
          onClick={() => removeOdd(item.odd.id)}
        >
          <FontAwesomeIcon icon={faTimes} className="betslip-item-remove-icon opacity-60" />
        </div>

        <div
          className={`betslip-item-content flex flex-grow px-3 py-2 overflow-hidden text-sm ${
            itemErrors?.length > 0
              ? itemErrors.find((error) => error.type === 'error')
                ? 'betslip-item-content-error bg-bet-slip-item-error'
                : 'betslip-item-content-warning bg-bet-slip-item-warning'
              : ''
          }`}
        >
          <div className="betslip-item-content-inner flex flex-col flex-grow overflow-hidden">
            <div className="betslip-item-content-details flex items-end justify-between">
              <div className="betslip-item-content-details-inner flex flex-col space-y-[2px] overflow-hidden w-full">
                <div
                  className="betslip-item-content-details-row flex justify-between items-center w-full overflow-hidden">
                  {!isBetBuilderMarket ? (
                    <span
                      className="betslip-item-content-details-market whitespace-nowrap overflow-hidden overflow-ellipsis text-xs opacity-60">
                      {item.market.name}
                    </span>
                  ) : (
                    <BetBuilderTag />
                  )}

                  <div className="betslip-item-content-details-date ml-4 whitespace-nowrap overflow-hidden text-xs">
                    {(item.game as Game)?.mode === GameMode.Live ? (
                      <span
                        className="betslip-item-content-details-date-live flex-shrink-0 text-live font-bold italic mr-[2px] uppercase">
                        {t('BetSlip.live')}
                      </span>
                    ) : (
                      <span className="betslip-item-content-details-date-prematch text-xxs font-semibold opacity-60">
                        {formatDateWithTime(item.game.startDate)}
                      </span>
                    )}
                  </div>
                </div>

                {isBetBuilderMarket && (
                  <div
                    className="betslip-item-content-details-row flex justify-between items-center space-x-2 overflow-hidden !mt-1">
                    <Link href={buildEventUrl(item.game.id, item.game.name, item.game.usesUsDisplay)} legacyBehavior>
                      <span
                        className="betslip-item-content-details-game whitespace-nowrap overflow-ellipsis overflow-hidden text-xs opacity-100 cursor-pointer">
                        {item.game.name}
                      </span>
                    </Link>

                    {sportsBettingSettings?.oddsDisplay?.betSlip?.showItemOdds && (
                      <div className="betslip-item-content-details-odd flex shrink-0 space-x-1 items-center">
                        <span className={`text-xs font-semibold ${oddsChange === 0 ? '' : 'line-through'}`}>
                          {getFormattedOddValue(oddsFormat, item.odd?.value)}
                        </span>
                        {oddsChange !== 0 && <ChangedOdd newOddValue={changedOdd.newValue} oddsChange={oddsChange} />}
                      </div>
                    )}
                  </div>
                )}

                <div
                  className="betslip-item-content-details-row flex justify-between items-center space-x-2 overflow-hidden text-xs font-semibold">
                  {!isBetBuilderMarket ? (
                    <>
                      <span
                        className="betslip-item-content-details-outcome flex whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {item.odd.name}
                      </span>
                      {sportsBettingSettings?.oddsDisplay?.betSlip?.showItemOdds && (
                        <div className="betslip-item-content-details-odd flex shrink-0 space-x-1 items-center">
                          <span className={`font-semibold ${oddsChange === 0 && !isBoostedOdd ? '' : 'line-through'}`}>
                            {getFormattedOddValue(oddsFormat, item.odd?.value)}
                          </span>
                          {isBoostedOdd ? (
                            <BoostedOdd
                              oddValue={item.odd.value}
                              boostedOddValue={boostedOddValue}
                              boosterPercentage={betslipContext?.selectedTokenBonus?.boosterPercentage}
                              bonusType={betslipContext?.selectedTokenBonus?.bonusType}
                            />
                          ) : (
                            <>
                              {oddsChange !== 0 && (
                                <ChangedOdd newOddValue={changedOdd.newValue} oddsChange={oddsChange} />
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <BetBuilderOdd oddName={item.odd.name} dotBackgroundColor={'bg-betslipItem'} className="mt-1" />

                      {betslipContext.betType === BetTypes.System && (
                        <div className="betslip-item-content-details-bank flex shrink-0">
                          <Switch
                            enabled={item.type === BetSlipItemType.Banker}
                            onClick={() => betslipContext.toggleOddBanked(item)}
                            handleColor={'bg-betslipContrast'}
                            handleLabelColor={'text-betslip'}
                            handleLabel={'B'}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>

                {!isBetBuilderMarket && (
                  <div
                    className="betslip-item-content-details-row flex justify-between items-center space-x-2 w-full h-[22px] overflow-hidden">
                    <Link href={buildEventUrl(item.game.id, item.game.name, item.game.usesUsDisplay)} legacyBehavior>
                      <span
                        className="betslip-item-content-details-game whitespace-nowrap overflow-ellipsis overflow-hidden text-xs opacity-100 cursor-pointer">
                        {item.game.name}
                      </span>
                    </Link>
                    {betslipContext.betType === BetTypes.System && (
                      <div className="betslip-item-content-details-bank flex shrink-0">
                        <Switch
                          enabled={item.type === BetSlipItemType.Banker}
                          onClick={() => betslipContext.toggleOddBanked(item)}
                          handleColor={'bg-betslipContrast'}
                          handleLabelColor={'text-betslip'}
                          handleLabel={'B'}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {itemErrors
              ?.filter(
                (ie) =>
                  ie.status !== CheckBetSlipItemStatus.OddLowered && ie.status !== CheckBetSlipItemStatus.OddRaised
              )
              ?.map((itemError) => (
                <div
                  className="betslip-item-content-details-errors mt-[2px] text-xs font-semibold text-primary"
                  key={itemError.status}
                  data-status={itemError.status}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                  {EnumTranslator(t, CheckBetSlipItemStatus, itemError.status)}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const BetSlipItemsCollapsed = ({ selectedOdds, itemsWithErrors, toggleCollapsed }) => {
  const { t } = useTranslation('bets')
  const { oddsFormat } = useCommonContext()
  const betslipContext = useBetslipContext()
  const mustAcceptChanges = betslipContext.mustAcceptChanges()
  const mustAcceptAnyChanges =
    mustAcceptChanges.mustAcceptAvailabilityChanges || mustAcceptChanges.mustAcceptOddsChanges
  const betSystem = betslipContext.betSystems?.find((sys) => sys.identifier === betslipContext.selectedBetSystem)
  const tipCount = betslipContext.betslips[0]?.itemCount

  return (
    <div
      className="betslip-items-collapsed flex p-3 justify-between items-start bg-betslipItem text-betslipItemContrast cursor-pointer"
      onClick={toggleCollapsed}
    >
      <div className="betslip-items-collapsed-inner flex flex-col space-y-1 items-start h-full">
        <span
          className={`betslip-items-collapsed-title text-sm font-semibold ${
            mustAcceptAnyChanges ? 'text-live' : 'text-betslipItemContrast'
          }`}
        >
          {betslipContext.betType === BetTypes.Combi && t('BetsPage.combiOf', { combination: tipCount })}

          {betslipContext.betType === BetTypes.Single &&
            (betslipContext.betAmount > 1
              ? `${betslipContext.betAmount} ${t('BetsPage.singleBets')}`
              : t('BetsPage.singleBet'))}

          {betslipContext.betType === BetTypes.System &&
            `${t('BetsPage.systemBet')} ${t('BetSlip.betSystemSelectorOption', {
              combi: betSystem?.combi,
              tipCount: tipCount
            })}`}
        </span>

        <span className="betslip-items-collapsed-outcomes text-xs font-light opacity-80">
          {selectedOdds?.map((selectedOdd, index) => {
            const isLast = index === selectedOdds.length - 1
            const hasError = itemsWithErrors.includes(selectedOdd.odd.id)
            const isBlockingError = hasError
              ? betslipContext.isBlockingItemError(
                betslipContext.betSlipItemErrors?.find((e) => e.oddId === selectedOdd.odd.id)
              )
              : false

            return (
              <span
                key={selectedOdd.odd.id}
                className={`betslip-items-collapsed-outcome ${
                  hasError && isBlockingError ? 'betslip-items-collapsed-outcome-error line-through' : ''
                }`}
              >
                {selectedOdd.odd.name}
                {!isLast ? ', ' : ''}
              </span>
            )
          })}
        </span>
      </div>

      {betslipContext.maxOdd > 0 && (
        <div>
          <span className="betslip-items-collapsed-max-odd text-sm font-semibold">
            {getFormattedOddValue(oddsFormat, betslipContext.maxOdd)}
          </span>
        </div>
      )}
    </div>
  )
}

const BetSlipItemsExpanded = ({ scrollToLastBetslipItem, removeOdd, selectedOdds }) => {
  const betslipContext = useBetslipContext()
  const betslipItemsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollToLastBetslipItem) {
      const containerHeight = betslipItemsRef?.current?.scrollHeight
      betslipItemsRef?.current?.scrollTo({ top: containerHeight, behavior: 'smooth' })
    }
  }, [betslipContext.selectedOdds?.length])

  return (
    <div
      ref={betslipItemsRef}
      className="betslip-items-expanded flex flex-col space-y-1 py-0 !mt-0 flex-grow w-full overflow-auto items-center"
    >
      {selectedOdds?.map((item) => <BetslipItem item={item} removeOdd={removeOdd} key={item.odd.id} />)}

      {selectedOdds?.length === 0 && (
        <div className="betslip-items-expanded-empty flex flex-col items-center space-y-4 px-12 pt-8 pb-10 text-sm">
          <FontAwesomeIcon
            icon={faRectangleList}
            className="betslip-items-expanded-empty-icon text-[100px] opacity-50"
          />
          <div className="flex flex-col items-center text-sm opacity-70">
            <Trans
              i18nKey="bets:BetSlip.noBetsSelected"
              components={[
                <span className="betslip-items-expanded-empty-title font-bold" />,
                <span className="betslip-items-expanded-empty-subtitle font-medium opacity-90 text-center" />
              ]}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export const BetslipItems = ({ removeOdd, scrollToLastBetslipItem = false, collapsed, toggleCollapsed }) => {
  const betslipContext = useBetslipContext()

  const itemsWithErrors = betslipContext.betSlipItemErrors?.map((error) => error.oddId)
  const selectedOdds = [
    ...betslipContext.selectedOdds?.filter((selectedOdd) => itemsWithErrors?.includes(selectedOdd.odd.id)),
    ...betslipContext.selectedOdds?.filter((selectedOdd) => !itemsWithErrors?.includes(selectedOdd.odd.id))
  ]

  return (
    <>
      {!collapsed ? (
        <BetSlipItemsExpanded
          scrollToLastBetslipItem={scrollToLastBetslipItem}
          removeOdd={removeOdd}
          selectedOdds={selectedOdds}
        />
      ) : (
        <>
          {selectedOdds?.length > 0 && (
            <BetSlipItemsCollapsed
              selectedOdds={selectedOdds}
              itemsWithErrors={itemsWithErrors}
              toggleCollapsed={toggleCollapsed}
            />
          )}
        </>
      )}
    </>
  )
}
