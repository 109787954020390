import React from 'react'
import { getPropsFromContentFields, getWidgetByUniqueIdentifier, NextWoqWidget } from '../../util/content'
import type { DisplayPredicate } from '@bmnext-cms/types/content/display-filter'
import { evalDisplayPredicates } from '../../util/display-predicate'
import { isAnonymousUser, useUser, useUserAccounts } from '../../lib/user'
import { useLayoutContext } from '../../context/layout.context'

function WidgetMainContainer({ cmsPage }) {
  const { user } = useUser()
  const { mainAccount } = useUserAccounts()
  const layoutContext = useLayoutContext()

  const isDesktop = layoutContext.currentBreakPoint === 'desktop'

  return (
    <>
      {cmsPage?.referencedWidgets?.map((referencedWidget, index) => {
        const referencedContent = referencedWidget.referencedContent
        const widgetIdentifier = referencedContent?.type?.uniqueId
        const Widget = getWidgetByUniqueIdentifier(widgetIdentifier)
        const widgetProps = getPropsFromContentFields(referencedContent)
        const displayPredicates: DisplayPredicate[] = referencedContent.displayFilter
          ? JSON.parse(referencedContent.displayFilter)
          : null
        const displayWidget = evalDisplayPredicates(displayPredicates, user, mainAccount)

        if (
          Widget == null ||
          !displayWidget ||
          (widgetIdentifier === NextWoqWidget.OpenBetsWidget && (isAnonymousUser(user) || isDesktop)) ||
          (widgetIdentifier === NextWoqWidget.OpenBonusWidget && (isAnonymousUser(user) || isDesktop)) ||
          (widgetIdentifier === NextWoqWidget.CasinoGamesWidget && isDesktop) ||
          (widgetIdentifier === NextWoqWidget.RankingWidget && isDesktop)
        ) {
          return <React.Fragment key={index}></React.Fragment>
        }

        return <Widget {...widgetProps} addPadding index={index} key={index} />
      })}
    </>
  )
}

export default WidgetMainContainer
