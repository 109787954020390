import { Game, Market } from '@arland-bmnext/api-data'
import { FrontEndMarketTypeTranslation } from '@bmnext-cms/types/content'
import { getGameCompetitors } from './game'
import { isNullOrEmpty } from './string'

export const translateGameMarkets = (game: Game, translations: FrontEndMarketTypeTranslation[]) => {
  if (!translations) return
  for (let translation of translations) {
    const markets = game?.markets?.filter((market) => market.marketTypeId === translation.marketTypeId)
    if (markets?.length > 0) {
      markets.forEach((market) => (market.name = replaceMarketPlaceholder(market, translation.name, game)))
    }
  }
}

export const replaceMarketPlaceholder = (market: Market, marketTranslation: string, game: Game): string => {
  let res = marketTranslation

  const competitorNamesFromGameName = getGameCompetitors(game)

  const comp1Name = !isNullOrEmpty(game?.competitors[0]?.name)
    ? game?.competitors[0]?.name
    : competitorNamesFromGameName[0]
  const comp2Name = !isNullOrEmpty(game?.competitors[1]?.name)
    ? game?.competitors[1]?.name
    : competitorNamesFromGameName[1]
  const currentServerName = !isNullOrEmpty(game?.competitors[game?.liveStatus?.currentServer]?.name)
    ? game?.competitors[game?.liveStatus?.currentServer]?.name
    : competitorNamesFromGameName[game?.liveStatus?.currentServer]

  res = res.replace('{Game.Name}', game?.name)
  res = res.replace('{Game.Competitors[0].Competitor.Name}', game?.usesUsDisplay ? comp2Name : comp1Name)
  res = res.replace('{Game.Competitors[1].Competitor.Name}', game?.usesUsDisplay ? comp1Name : comp2Name)
  res = res.replace('{Game.CurrentServerName}', currentServerName)

  res = res.replace('{SpecialIntValue1}', market?.specialIntValue1.toString())
  res = res.replace('{SpecialIntValue2}', market?.specialIntValue2.toString())
  res = res.replace('{SpecialIntValue3}', market?.specialIntValue3.toString())

  res = res.replace('{SpecialStringValue1}', market?.specialStringValue1)
  res = res.replace('{SpecialStringValue2}', market?.specialStringValue1)
  res = res.replace('{SpecialStringValue3}', market?.specialStringValue3)

  res = res.replace('{SpecialSingleValue1}', market?.specialSingleValue1.toString())
  res = res.replace('{SpecialSingleValue2}', market?.specialSingleValue2.toString())
  res = res.replace('{SpecialSingleValue3}', market?.specialSingleValue3.toString())

  return res
}

export const getMarketTypeTranslationWithGameData = (
  game: Game,
  market: Market,
  marketTypeTranslations: FrontEndMarketTypeTranslation[]
): FrontEndMarketTypeTranslation => {
  const marketTypeTranslation = marketTypeTranslations?.find(
    (translation) => translation.marketTypeId === market?.marketTypeId
  )
  if (marketTypeTranslation) {
    const res = { ...marketTypeTranslation }
    res.name = replaceMarketPlaceholder(market, res.name, game)
    return res
  }
  return null
}
